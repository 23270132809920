<template>
    <section id='newPass'>
        <form class='narrowBox' @submit.prevent='submit' autocomplete='off'>
            <h1>
                {{ $parse('ברוך הבא','ברוכה הבאה','ברוכים הבאים') }} לתיק חולה משותף!
            </h1>
            <div class='usernameError' :class='{ error: !valid.email }'>
                כתובת המייל שרשמת לא תואמת את הכתובת ששמורה לנו במערכת
            </div>
            <h2>
                כדי לסיים את תהליך ההרשמה, אנחנו מבקשים להזין שם משתמש וססיסמה אשר ישמשו אותך להתחברות למערכת.
                שם המשתמש אינו חובה - ניתן להתחבר למערכת גם באמצעות כתובת מייל.
            </h2>
            <label>
                {{ $parse('בחר', 'בחרי')}} שם משתמש:
                <input v-model.trim='username' :class='{ error: !valid.usernameAvailable || !valid.username }' @input='resetErrors(["username", "usernameAvailable"])' />
                <div className='small'>(עד 15 תווים, אותיות לועזיות בלבד)</div>
            </label>
            <div class='usernameError' :class='{ error: !valid.username }'>
                שם המשתמש צריך להיות קצר מ15 תווים
            </div>
            <div class='usernameError' :class='{ error: !valid.usernameAvailable }'>
                שם המשתמש שבחרת כבר תפוס
            </div>
            <div id='passwordBox'>
                <div>
                    <label>
                        סיסמה חדשה:
                        <!--Confuse Firefox autofill--><input style='display:none' /><input style='display:none' type='password' />
                        <input v-model='password' type='password' @input='resetErrors(["passwordRepeat", "match"])' />
                    </label>
                    <label>
                        {{ $parse('חזור','חזרי','חזרו')}}
                        על הסיסמה:
                        <input
                            v-model='passwordRepeat'
                            type='password'
                            @input='resetErrors(["passwordRepeat", "match"])'
                            :class='{ error: !valid.passwordRepeat }'
                        />
                    </label>
                </div>
                <div id='passwordIndicators'>
                    <div v-for='{ text }, key in passwordValidations' :key='key' :class='{ isValid: valid.password[key] }'>
                        <span class='material-icons-round'>{{ !valid.password[key] ? 'highlight_off' : 'check_circle' }}</span>
                        <span v-html='`מכילה ${text}`' />
                    </div>
                </div>
            </div>
            <div class='didNotMatch' :class='{ error: !valid.match }'>
                הסיסמאות שרשמת אינן זהות זו לזו
            </div>
            <h2 v-if='role === "patient"'>
                בנוסף, נבקש גם כמה פרטים מזהים. אף אחד מהשדות אינו חובה, וניתן להשאיר אחד או יותר מהם ריקים.
                <div>
                    <b>חשוב לדעת:</b> הפרטים הללו נשמרים בנפרד מהמידע הרפואי, אשר נשמר באופן אנונימי לחלוטין.
                    היחידים שחשופים למידע המזהה הם {{ $parse('אתה', 'את') }} ומערכת התזכורות שתפעל בהמשך.
                </div>
            </h2>
            <h2 v-else>
                בנוסף, נבקש גם כמה פרטים מזהים.
                הפרטים הללו יאפשרו למשתמשים אחרים לזהות אתכם, ולמערכת לשלוח לכם עדכונים במקרה הצורך.
            </h2>
            <label>שם מלא: <input v-model.trim='name' /></label>
            <label>טלפון: <input v-model.trim='phone' /></label>
            <button>
                <span v-if='!loading'>סיום</span>
                <img v-else src='/images/loading.gif' />
            </button>
        </form>
    </section>
</template>

<script lang='ts'>
import { sendRequest } from "@/SharedChart/main";
import { defineComponent } from "@vue/runtime-core";

const passwordValidations = {
    length: { regex: /.{8,}/, text: 'לפחות 8 תווים'},
    specialCharacters: { regex: /(!|@|#|\$|%|\^|&|\*)/, text: 'תווים מיוחדים <div class="small" style="margin-top: -.2rem">(!@#$%^&*)</div>' },
    uppercase: { regex: /[A-Z]/, text: 'אותיות גדולות' },
    lowercase: { regex: /[a-z]/, text: 'אותיות קטנות' },
    numbers: { regex: /[0-9]/, text: 'מספרים' }
}

const valid = {
    match: true,
    password: Object.fromEntries(Object.keys(passwordValidations).map(key => [key, false])),
    passwordRepeat: true,
    username: true,
    usernameAvailable: true,
    email: true
}
type validKeys = keyof typeof valid;

export default defineComponent({
    async beforeRouteEnter(_, _2, next){
        try{
            const token = window.location.pathname.split('/').slice(-1);
            const response = await sendRequest(`/user/signup/${token}`);
            next((vm: any) => vm.role = response.body.role);
        }catch(error){
            console.error(error);
            const expired = (error as Error).message === 'token_expired'
            next(`/signup/${expired ? 'expired' : 'invalid'}`);
        }
    },
    data: () => ({
        email: '',
        role: null,
        password: '',
        passwordRepeat: '',
        valid,
        username: '',
        name: '',
        phone: '',
        disabled: false,
        passwordValidations,
        loading: false
    }),
    computed: {
        token(){
            return this.$route.params.token;
        }
    },
    watch: {
        password(password){
            for(const key in passwordValidations)
                this.valid.password[key] = passwordValidations[key as keyof typeof passwordValidations].regex.test(password);
        }
    },
    methods: {
        resetErrors(fields: Exclude<validKeys, 'password'>[]){
            fields.forEach(field => this.valid[field] = true);
        },
        validate(){
            const { username, password, passwordRepeat } = this;
            let out = true;

            const conditions: { [key: string]: boolean } = {
                match: password === passwordRepeat,
                password: !Object.values(this.valid.password).includes(false) && /^([a-zA-Z0-9]|!|@|#|\$|%|\^|&|\*)+$/.test(password),
                passwordRepeat: passwordRepeat.length > 0,
                username: !username || (username.length <= 15 && /^[a-zA-Z]+$/.test(username))
            }

            for(const c in conditions){
                const key = c as validKeys;
                if(!conditions[key] && key !== 'password')
                    this.valid[key] = out = false;
            }

            return out;
        },
        async submit(){
            this.loading = true;
            if(!this.validate())
                return;

            const { username, password, token, name, phone } = this;

            try{
                await this.$request(`/user/signup/${token}`, 'put', { username, password, token, name, phone });
                this.$router.replace('/signup/completed');
            }catch(error: any){
                this.loading = false;
                const message = JSON.parse(error.message);
                const errors = {
                    username_taken: () => this.valid.usernameAvailable = false
                }

                for(const e in errors)
                    if(message === e)
                        errors[e as keyof typeof errors]();
                
                if(!Object.keys(errors).includes(message))
                    this.$store.commit('setToast', { text: `משהו השתבש. ${this.$parse('נסה','נסי')} שוב` });
            }
        },
    }
});
</script>

<style lang='scss'>
#newPass {
    margin: 2rem auto 0;

    & .narrowBox {
        min-width: 33rem;
    }

    & h2 {
        font-size: 1rem;
        text-align: justify;
        padding: 1rem 2rem .5rem;
        border-top: $borderLight;

        & b {
            font-weight: bold;
        }
    }

    & #emailInput {
        margin: 1rem auto;
    }

    #passwordBox {
        display: flex;
        padding: 0 1rem;
        align-items: center;
        margin-bottom: 1rem;

        & > div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        & #passwordIndicators {
            margin-right: 1rem;
            padding: .5rem .7rem .5rem 1.5rem;
            background-color: $swatchC;
            border-radius: $corner;
            flex-grow: 0;
            flex-shrink: 0;

            & .material-icons-round {
                margin-left: .5rem;
                font-size: 1.1rem;
                transform: translateY(.25rem);
            }
            
            & > div {
                display: flex;
                color: #777;

                &:not(:last-child){
                    text-align-last: justify;
                }

                &.isValid {
                    color: rgb(6, 153, 30);
                }

                & > :last-child {
                    flex: 1;
                }
            }
        }
    }

    & .didNotMatch, & .currentInvalid, & .usernameError {
        display: none;

        &.error {
            display: flex;
            justify-content: center;
            border: none !important;
            background-color: #f2c2c3;
            padding: 0.5rem 1rem !important;
            color: black !important;
            width: 16rem;
        }
    }

    .usernameError.error {
        margin: -.3rem auto 1.5rem;
        width: 18rem;
    }

    & .didNotMatch.error {
        margin: .2rem auto 1.3rem !important;
        width: 18rem;
    }

    & .currentInvalid.error {
        margin: 1rem auto 2rem !important;
    }

    & button {
        margin-top: 2rem;
        min-width: 8rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            width: 3rem;
        }
    }
}
</style>