
import { sendRequest } from "@/SharedChart/main";
import { defineComponent } from "@vue/runtime-core";

const passwordValidations = {
    length: { regex: /.{8,}/, text: 'לפחות 8 תווים'},
    specialCharacters: { regex: /(!|@|#|\$|%|\^|&|\*)/, text: 'תווים מיוחדים <div class="small" style="margin-top: -.2rem">(!@#$%^&*)</div>' },
    uppercase: { regex: /[A-Z]/, text: 'אותיות גדולות' },
    lowercase: { regex: /[a-z]/, text: 'אותיות קטנות' },
    numbers: { regex: /[0-9]/, text: 'מספרים' }
}

const valid = {
    match: true,
    password: Object.fromEntries(Object.keys(passwordValidations).map(key => [key, false])),
    passwordRepeat: true,
    username: true,
    usernameAvailable: true,
    email: true
}
type validKeys = keyof typeof valid;

export default defineComponent({
    async beforeRouteEnter(_, _2, next){
        try{
            const token = window.location.pathname.split('/').slice(-1);
            const response = await sendRequest(`/user/signup/${token}`);
            next((vm: any) => vm.role = response.body.role);
        }catch(error){
            console.error(error);
            const expired = (error as Error).message === 'token_expired'
            next(`/signup/${expired ? 'expired' : 'invalid'}`);
        }
    },
    data: () => ({
        email: '',
        role: null,
        password: '',
        passwordRepeat: '',
        valid,
        username: '',
        name: '',
        phone: '',
        disabled: false,
        passwordValidations,
        loading: false
    }),
    computed: {
        token(){
            return this.$route.params.token;
        }
    },
    watch: {
        password(password){
            for(const key in passwordValidations)
                this.valid.password[key] = passwordValidations[key as keyof typeof passwordValidations].regex.test(password);
        }
    },
    methods: {
        resetErrors(fields: Exclude<validKeys, 'password'>[]){
            fields.forEach(field => this.valid[field] = true);
        },
        validate(){
            const { username, password, passwordRepeat } = this;
            let out = true;

            const conditions: { [key: string]: boolean } = {
                match: password === passwordRepeat,
                password: !Object.values(this.valid.password).includes(false) && /^([a-zA-Z0-9]|!|@|#|\$|%|\^|&|\*)+$/.test(password),
                passwordRepeat: passwordRepeat.length > 0,
                username: !username || (username.length <= 15 && /^[a-zA-Z]+$/.test(username))
            }

            for(const c in conditions){
                const key = c as validKeys;
                if(!conditions[key] && key !== 'password')
                    this.valid[key] = out = false;
            }

            return out;
        },
        async submit(){
            this.loading = true;
            if(!this.validate())
                return;

            const { username, password, token, name, phone } = this;

            try{
                await this.$request(`/user/signup/${token}`, 'put', { username, password, token, name, phone });
                this.$router.replace('/signup/completed');
            }catch(error: any){
                this.loading = false;
                const message = JSON.parse(error.message);
                const errors = {
                    username_taken: () => this.valid.usernameAvailable = false
                }

                for(const e in errors)
                    if(message === e)
                        errors[e as keyof typeof errors]();
                
                if(!Object.keys(errors).includes(message))
                    this.$store.commit('setToast', { text: `משהו השתבש. ${this.$parse('נסה','נסי')} שוב` });
            }
        },
    }
});
